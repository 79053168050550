import { SanityClient } from '@sanity/client';
import {
	SanityAsset,
	SanityImageObject,
	SanityImageSource,
	SanityReference,
} from '@sanity/image-url/lib/types/types';
import { ActionType, IconType } from '@wearemojo/sanity-schema';
import { ImageProps } from 'expo-image';
import { ComponentType, createContext, useContext } from 'react';
import BellIcon from 'react-native-heroicons/outline/BellIcon';
import BookOpenIcon from 'react-native-heroicons/outline/BookOpenIcon';
import ChatIcon from 'react-native-heroicons/outline/ChatIcon';
import CheckCircleIcon from 'react-native-heroicons/outline/CheckCircleIcon';
import ClockIcon from 'react-native-heroicons/outline/ClockIcon';
import CloudIcon from 'react-native-heroicons/outline/CloudIcon';
import CreditCardIcon from 'react-native-heroicons/outline/CreditCardIcon';
import DocumentIcon from 'react-native-heroicons/outline/DocumentIcon';
import ExclamationIcon from 'react-native-heroicons/outline/ExclamationIcon';
import EyeIcon from 'react-native-heroicons/outline/EyeIcon';
import FastForwardIcon from 'react-native-heroicons/outline/FastForwardIcon';
import FireIcon from 'react-native-heroicons/outline/FireIcon';
import FlagIcon from 'react-native-heroicons/outline/FlagIcon';
import GiftIcon from 'react-native-heroicons/outline/GiftIcon';
import HandIcon from 'react-native-heroicons/outline/HandIcon';
import HomeIcon from 'react-native-heroicons/outline/HomeIcon';
import InformationCircleIcon from 'react-native-heroicons/outline/InformationCircleIcon';
import LightBulbIcon from 'react-native-heroicons/outline/LightBulbIcon';
import LightningBoltIcon from 'react-native-heroicons/outline/LightningBoltIcon';
import LockClosedIcon from 'react-native-heroicons/outline/LockClosedIcon';
import MapIcon from 'react-native-heroicons/outline/MapIcon';
import MenuIcon from 'react-native-heroicons/outline/MenuIcon';
import MoonIcon from 'react-native-heroicons/outline/MoonIcon';
import QuestionMarkCircleIcon from 'react-native-heroicons/outline/QuestionMarkCircleIcon';
import RefreshIcon from 'react-native-heroicons/outline/RefreshIcon';
import SearchIcon from 'react-native-heroicons/outline/SearchIcon';
import SparklesIcon from 'react-native-heroicons/outline/SparklesIcon';
import UserIcon from 'react-native-heroicons/outline/UserIcon';
import UsersIcon from 'react-native-heroicons/outline/UsersIcon';
import VideoCameraIcon from 'react-native-heroicons/outline/VideoCameraIcon';
import VolumeOffIcon from 'react-native-heroicons/outline/VolumeOffIcon';
import VolumeUpIcon from 'react-native-heroicons/outline/VolumeUpIcon';
import XIcon from 'react-native-heroicons/outline/XIcon';
import { SvgProps } from 'react-native-svg';

import useSanityImageAttributes from '../hooks/useSanityImageAttributes';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';
import { CaptureError } from './errors';
import { ActionLink } from './types';

export type SanityImageDimensions = {
	width: number;
	height: number;
	aspectRatio: number;
};

export const SanityContext = createContext<SanityClient | null>(null);

export type CMSGlobalContextType = {
	imgAttr: ReturnType<typeof useSanityImageAttributes>;
	transformActionToActionLink: (action: ActionType) => ActionLink | undefined;
	captureError?: CaptureError;
	therapistImage?: ImageProps['source'];
	therapistName?: string;
	streamingFeatureEnabled?: boolean;
};

export const CMSGlobalContext = createContext<CMSGlobalContextType | null>(
	null,
);

export const useCMSGlobalContext = () => {
	const context = useContext(CMSGlobalContext);
	if (!context) {
		throw new Error('Missing required CMSGlobalContext');
	}
	return context;
};

// Source: https://github.com/bundlesandbatches/next-sanity-image/blob/cfb99cfa8f9cfd993e1f4c07bcd5667b09826ddf/src/useNextSanityImage.ts
export const getSanityImageRefId = (image: SanityImageSource): string => {
	if (typeof image === 'string') {
		return image;
	}

	const obj = image as SanityImageObject;
	const ref = image as SanityReference;
	const img = image as SanityAsset;

	if (typeof image === 'string') {
		return image;
	}

	if (obj.asset) {
		return obj.asset._ref || (obj.asset as SanityAsset)._id;
	}

	return ref._ref || img._id || '';
};

export const getSanityImageFormat = (id: string): string | undefined => {
	const fragments = id.split('-');
	return fragments[fragments.length - 1];
};

export const getSanityImageDimensions = (
	id: string,
): SanityImageDimensions | undefined => {
	const dimensions = id.split('-')[2];
	if (!dimensions) return undefined;

	const [width, height] = dimensions
		.split('x')
		.map((num: string) => parseInt(num, 10));
	if (width == null || height == null) return undefined;

	const aspectRatio = width / height;

	return { width, height, aspectRatio };
};

export const createCMSContentBlock = (
	text: string,
	attrs: { style?: string } = {},
) => {
	const { style = 'normal' } = attrs;
	const _key = key(`[${style}] ${text}`);
	return {
		_type: 'block',
		_key,
		style,
		markDefs: [],
		children: [{ _type: 'span', text }],
	};
};

const key = (s: string): string => {
	let h = 0;
	let l = s.length;
	let i = 0;
	if (l > 0) {
		while (i < l) {
			// eslint-disable-next-line no-bitwise
			h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
		}
	}
	return `key-${h}`;
};

export const cmsIcons: Record<
	IconType,
	ComponentType<SvgProps & { size?: number }>
> = {
	bell: BellIcon,
	book_open: BookOpenIcon,
	chat: ChatIcon,
	check_circle: CheckCircleIcon,
	clock: ClockIcon,
	cloud: CloudIcon,
	credit_card: CreditCardIcon,
	document: DocumentIcon,
	exclamation: ExclamationIcon,
	external_link: ExternalLinkIcon,
	eye: EyeIcon,
	fire: FireIcon,
	flag: FlagIcon,
	forward: FastForwardIcon,
	gift: GiftIcon,
	hand: HandIcon,
	home: HomeIcon,
	information_circle: InformationCircleIcon,
	light_bulb: LightBulbIcon,
	lightning_bolt: LightningBoltIcon,
	lock_closed: LockClosedIcon,
	map: MapIcon,
	menu: MenuIcon,
	moon: MoonIcon,
	question_mark_circle: QuestionMarkCircleIcon,
	refresh: RefreshIcon,
	search: SearchIcon,
	sparkles: SparklesIcon,
	user: UserIcon,
	users: UsersIcon,
	video_camera: VideoCameraIcon,
	volume_off: VolumeOffIcon,
	volume_up: VolumeUpIcon,
	x: XIcon,
};

export const TestContentFixture = [
	{
		_type: 'block',
		_key: 'head',
		style: 'h1',
		markDefs: [],
		children: [{ _type: 'span', text: 'CMSContent demo' }],
	},
	{
		_type: 'block',
		_key: 'text-format-header',
		style: 'h2',
		children: [{ _type: 'span', _key: 'a', text: 'Text formatting' }],
	},
	{
		_type: 'block',
		_key: 'text-formatting',
		markDefs: [
			{ _type: 'link', _key: 'd4tl1nk', href: 'https://portabletext.org/' },
		],
		children: [
			{ _type: 'span', _key: 'a', text: 'Plain, ' },
			{ _type: 'span', _key: 'b', text: 'emphasized, ', marks: ['em'] },
			{ _type: 'span', _key: 'c', text: 'linked', marks: ['d4tl1nk'] },
			{ _type: 'span', _key: 'd', text: ' and ', marks: ['em'] },
			{ _type: 'span', _key: 'e', text: 'strong', marks: ['strong'] },
			{ _type: 'span', _key: 'f', text: ' text, that can also be ', marks: [] },
			{
				_type: 'span',
				_key: 'g',
				text: 'combined',
				marks: ['em', 'strong', 'd4tl1nk'],
			},
			{
				_type: 'span',
				_key: 'g',
				text: '. Obviously it also supports ',
				marks: [],
			},
			{ _type: 'span', _key: 'h', text: 'inline code', marks: ['code'] },
			{ _type: 'span', _key: 'i', text: ', ' },
			{
				_type: 'span',
				_key: 'j',
				text: 'underlined text',
				marks: ['underline'],
			},
			{ _type: 'span', _key: 'k', text: ' and ' },
			{
				_type: 'span',
				_key: 'l',
				text: 'strike-through',
				marks: ['strike-through'],
			},
			{ _type: 'span', _key: 'm', text: '.' },
		],
	},
	{
		_type: 'block',
		_key: 'inline-objects',
		style: 'normal',
		children: [
			{ _type: 'span', text: 'Blocks can also contain "' },
			{ _type: 'span', text: 'inline objects', marks: ['em'] },
			{
				_type: 'span',
				text: '", which contain user-defined data.',
			},
		],
	},
	{
		_type: 'block',
		_key: 'blocks-expl-header',
		style: 'h2',
		children: [{ _type: 'span', _key: 'z', text: 'Blocks' }],
	},
	{
		_type: 'block',
		_key: 'block-intro',
		children: [
			{
				_type: 'span',
				_key: 'z',
				text: '"Blocks" in Portable Text are... well, block-level items.\nBy default, you will see things like blockquotes, headings and regular paragraphs.',
			},
		],
	},
	{
		_type: 'block',
		_key: 'list-head',
		style: 'h2',
		markDefs: [],
		children: [{ _type: 'span', text: 'Lists' }],
	},
	{
		_type: 'block',
		_key: 'list-intrp',
		style: 'normal',
		markDefs: [],
		children: [{ _type: 'span', text: 'Of course, you will want lists!' }],
	},
	{
		_type: 'block',
		listItem: 'bullet',
		children: [{ _type: 'span', text: 'They are fully supported' }],
	},
	{
		_type: 'block',
		listItem: 'bullet',
		children: [{ _type: 'span', text: 'They can be unordered or ordered' }],
	},
	{
		_type: 'block',
		listItem: 'bullet',
		children: [
			{ _type: 'span', text: 'Reasons why ordered lists might be better:' },
		],
	},
	{
		_type: 'block',
		listItem: 'number',
		level: 2,
		children: [
			{ _type: 'span', text: 'Most lists have ' },
			{ _type: 'span', text: 'some', marks: ['em'] },
			{ _type: 'span', text: ' priority' },
		],
	},
	{
		_type: 'block',
		listItem: 'number',
		level: 2,
		children: [
			{ _type: 'span', text: 'Not conveying importance/priority is lazy' },
		],
	},
];
