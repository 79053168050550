import { InlineContentWrapperType } from '@wearemojo/sanity-schema';
import { UIThemeColors } from '@wearemojo/ui-constants';
import { ImageProps } from 'expo-image';
import { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import Animated, {
	useAnimatedStyle,
	useSharedValue,
	withTiming,
} from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import AccountAvatar from '../AccountAvatar';
import CMSContext from '../cms/CMSContext';
import Text from '../Text';
import { ContentVariables, TextVariantish } from '../utils/types';
import { useStreaming } from './StreamingContext';

type ItoAIMessageProps = {
	messages: string[] | InlineContentWrapperType[];
	avatarUrl: ImageProps['source'];
	hideAvatar?: boolean;
	textVariant?: 'body_lg' | 'ito_message';
	avatarSize?: 'small' | 'medium' | 'large' | 'flow_medium';
	therapistName?: string;
	streaming?: boolean;
	streamingSpeed?: number;
};

// Extract text from InlineContentWrapper
const extractTextFromContent = (
	content: InlineContentWrapperType | string,
	contentVariables: ContentVariables,
): string[] => {
	try {
		if (typeof content === 'string') {
			return [content];
		}
		if (!content.content || !Array.isArray(content.content)) return [];

		// Extract each block's text separately to preserve paragraph breaks
		return content.content
			.map((block) => {
				if (!block.children || !Array.isArray(block.children)) return '';

				return block.children.reduce((childText, child) => {
					if (child._type === 'span' && typeof child.text === 'string') {
						if (contentVariables[child.text]) {
							return childText + contentVariables[child.text];
						}
						return childText + child.text;
					}
					return childText;
				}, '');
			})
			.filter((text) => text.trim() !== '');
	} catch (error) {
		console.error('Error extracting text from content:', error);
		return [];
	}
};

const AnimatedWord = ({
	word,
	index,
	delay,
	textVariant,
	themeColor,
}: {
	word: string;
	index: number;
	delay: number;
	textVariant: TextVariantish;
	themeColor: keyof UIThemeColors;
}) => {
	const { styles } = useStyles(stylesheet);
	const opacity = useSharedValue(0);

	useEffect(() => {
		const timeout = setTimeout(() => {
			opacity.value = withTiming(1, { duration: 300 });
		}, delay * index);

		return () => clearTimeout(timeout);
	}, [delay, index, opacity]);

	const animatedStyle = useAnimatedStyle(() => {
		return {
			opacity: opacity.value,
		};
	});

	return (
		<Animated.View style={[styles.word, animatedStyle]}>
			<Text variant={textVariant} themeColor={themeColor}>
				{word}
			</Text>
		</Animated.View>
	);
};

const StreamingText = ({
	message,
	textVariant,
	themeColor,
	contentVariables,
	streamingSpeed = 100,
	setStreamingFinished,
}: {
	message: string | InlineContentWrapperType;
	textVariant: 'body_lg' | 'ito_message';
	themeColor: keyof UIThemeColors;
	contentVariables: ContentVariables;
	streamingSpeed: number;
	setStreamingFinished: (value: boolean) => void;
}) => {
	const { styles } = useStyles(stylesheet);
	const [blocks, setBlocks] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const extractedBlocks = extractTextFromContent(message, contentVariables);
		setBlocks(extractedBlocks);
		setIsLoading(false);
	}, [message, contentVariables]);

	useEffect(() => {
		if (blocks.length === 0 || isLoading) return;

		// Count total words across all blocks
		const totalWords = blocks.reduce((total, block) => {
			const words = block.split(' ').filter((word) => word.trim() !== '');
			return total + words.length;
		}, 0);

		// If there are very few words, we should show the CMS content sooner
		const threshold = totalWords <= 10 ? 0.3 : 0.7;
		const wordCountThreshold = Math.floor(totalWords * threshold);
		const timeToThreshold = wordCountThreshold * streamingSpeed;

		const timer = setTimeout(() => {
			setStreamingFinished(true);
		}, timeToThreshold);

		return () => clearTimeout(timer);
	}, [blocks, streamingSpeed, setStreamingFinished, isLoading]);

	if (isLoading) {
		return null;
	}

	if (blocks.length === 0) {
		return (
			<Text.CMSContent
				value={message}
				variant={textVariant}
				themeColor={themeColor}
				contentVariables={contentVariables}
			/>
		);
	}

	let wordIndexOffset = 0;

	return (
		<View style={styles.blocksContainer}>
			{blocks.map((blockText, blockIndex) => {
				const words = blockText.split(' ').filter((word) => word.trim() !== '');
				const currentOffset = wordIndexOffset;
				wordIndexOffset += words.length;

				return (
					<View key={blockIndex} style={styles.blockContainer}>
						<View style={styles.wordsContainer}>
							{words.map((word, wordIndex) => {
								const globalIndex = currentOffset + wordIndex;

								return (
									<AnimatedWord
										key={`${blockIndex}-${wordIndex}`}
										word={word}
										index={globalIndex}
										delay={streamingSpeed}
										textVariant={textVariant}
										themeColor={themeColor}
									/>
								);
							})}
						</View>
					</View>
				);
			})}
		</View>
	);
};

const ItoAIMessage = ({
	messages,
	avatarUrl,
	hideAvatar = false,
	textVariant = 'body_lg',
	avatarSize = 'small',
	therapistName,
	streaming = false,
	streamingSpeed = 50,
}: ItoAIMessageProps) => {
	const { styles } = useStyles(stylesheet);
	const { contentVariables } = useContext(CMSContext);
	const { setStreamingFinished } = useStreaming();
	const isStreamingEnabled = streaming;

	return (
		<View style={styles.messageContainer}>
			{messages.map((message, index) => {
				const showAvatar = !hideAvatar && index === 0;
				return (
					<View
						key={index}
						style={[styles.message, !showAvatar && styles.textMargin]}
					>
						{showAvatar && (
							<AccountAvatar
								size={avatarSize}
								avatarUrl={avatarUrl}
								noShimmer
							/>
						)}
						<View style={styles.textContainer}>
							{isStreamingEnabled ? (
								<StreamingText
									message={message}
									textVariant={textVariant}
									themeColor="content_neutral100"
									contentVariables={{ ...contentVariables, therapistName }}
									streamingSpeed={streamingSpeed}
									setStreamingFinished={setStreamingFinished}
								/>
							) : (
								<Text.CMSContent
									value={message}
									variant={textVariant}
									themeColor="content_neutral100"
									contentVariables={{ ...contentVariables, therapistName }}
								/>
							)}
						</View>
					</View>
				);
			})}
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	messageContainer: {
		gap: spacing.xs,
	},
	message: {
		maxWidth: '80%',
		flexDirection: 'row',
		gap: spacing.md,
	},
	textContainer: {
		width: '100%',
	},
	textMargin: {
		marginLeft: spacing.xl4,
	},
	wordsContainer: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	word: {
		marginRight: 4,
	},
	blocksContainer: {
		width: '100%',
	},
	blockContainer: {
		marginBottom: spacing.md,
		width: '100%',
	},
}));

export default ItoAIMessage;
