import { useCallback, useEffect, useState } from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import {
	Easing,
	ReduceMotion,
	runOnJS,
	useAnimatedReaction,
	useAnimatedStyle,
	useDerivedValue,
	useSharedValue,
	withTiming,
	WithTimingConfig,
} from 'react-native-reanimated';
import { useSafeAreaFrame } from 'react-native-safe-area-context';

type AnimatedOverlayOptions = {
	/**
	 * - [0, 1]: container height percentage
	 * - \>= 1: fixed size
	 */
	contentHeight: number;
	/**
	 * Defaults to screen height
	 */
	containerHeight?: number;
};

const useAnimatedOverlay = ({
	contentHeight,
	containerHeight,
}: AnimatedOverlayOptions) => {
	if (contentHeight < 0) {
		throw new Error('Unexpected negative contentHeight');
	}
	if (containerHeight != null && containerHeight < 0) {
		throw new Error('Unexpected negative containerHeight');
	}

	const { height: WINDOW_HEIGHT } = useWindowDimensions();
	const { height: SAFE_AREA_FRAME_HEIGHT } = useSafeAreaFrame();

	// useWindowDimensions + translucent system ui returns incorrect dimensions on android
	// https://github.com/facebook/react-native/issues/41918
	const DEVICE_HEIGHT =
		Platform.OS === 'android' ? SAFE_AREA_FRAME_HEIGHT : WINDOW_HEIGHT;
	const CONTAINER_HEIGHT = containerHeight ?? DEVICE_HEIGHT;

	const OVERLAY_HEIGHT =
		contentHeight <= 1
			? // contentHeight as percentage of container
				CONTAINER_HEIGHT * contentHeight
			: // contentHeight as fixed height
				contentHeight;

	const [expanded, setExpand] = useState(false);
	const [fullyClosed, setFullyClosed] = useState(true);

	// 0 => closed; 1 => open;
	const expandedProgress = useTiming(expanded, {
		duration: 500,
		easing: Easing.bezier(0.25, 0, 0.25, 1.1),
	});

	useAnimatedReaction(
		() => expandedProgress.value,
		(currentValue, previousValue) => {
			if (currentValue === 0) {
				runOnJS(setFullyClosed)(true);
			}
			if (previousValue === 0) {
				runOnJS(setFullyClosed)(false);
			}
		},
	);

	const translateY = useDerivedValue(() => {
		return -expandedProgress.value * OVERLAY_HEIGHT;
	});

	const toggleOverlay = useCallback(() => {
		setExpand((prev) => !prev);
	}, []);

	const present = useCallback(() => {
		setExpand(true);
	}, []);

	const dismiss = useCallback(() => {
		setExpand(false);
	}, []);

	const translateStyle = useAnimatedStyle(() => ({
		transform: [{ translateY: translateY.value }],
	}));

	const topStyle = { top: CONTAINER_HEIGHT };

	return {
		toggleOverlay,
		translateStyle,
		topStyle,
		expanded,
		dismiss,
		present,
		expandedProgress,
		overlayHeight: OVERLAY_HEIGHT,
		fullyClosed,
	};
};

const useTiming = (state: boolean, config?: WithTimingConfig) => {
	const value = useSharedValue(0);
	useEffect(() => {
		value.value = state ? 1 : 0;
	}, [state, value]);
	const transition = useDerivedValue(() => {
		return withTiming(value.value, {
			...config,
			reduceMotion: ReduceMotion.Never,
		});
	});
	return transition;
};

export default useAnimatedOverlay;
