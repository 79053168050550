export enum Spacing {
	extraSmall = 4,
	small = 8,
	regular = 12,
	large = 16,
	extraLarge = 32,
	// deviations from DS, @TODO review with design
	dsDeviation__10 = 10,
	dsDeviation__20 = 20,
	dsDeviation__24 = 24,
	dsDeviation__40 = 40,
	dsDeviation__48 = 48,
	dsDeviation__60 = 60,
}

export enum Font {
	body = 'ESKlarheitGrotesk-Md',
	bodyBold = 'ESKlarheitGrotesk-Smbd',
	bodyItalic = 'ESKlarheitGrotesk-MdIt',
	bodyBoldItalic = 'ESKlarheitGrotesk-SmbdIt',
	heading = 'National2Condensed-Medium',
	headingBold = 'National2Condensed-Bold',
	headingExtraBold = 'National2Condensed-Extrabold',
	headingItalic = 'National2Condensed-MediumItalic',
	headingBoldItalic = 'National2Condensed-BoldItalic',
	headingExtraBoldItalic = 'National2Condensed-ExtraboldItalic',
}

export enum BrandColor {
	black = '#000000',
	black_600 = 'rgba(0, 0, 0, 0.60)',
	neutral_100 = '#EAEAEA',
	neutral_200 = '#ABABAB',
	neutral_300 = '#515151',
	neutral_400 = '#454545',
	neutral_500 = '#3C3C3C',
	neutral_600 = '#373737',
	neutral_700 = '#292929',
	neutral_800 = '#1F1F1F',
	neutral_base = '#F5F5F5',
	primary_blue = '#35C8FE',
	primary_blue_darker = '#2969EF',
	primary_blue_lighter = '#BAECFF',
	primary_green = '#3CE7A1',
	primary_green_darker = '#00D17C',
	primary_green_lighter = '#BAFFE3',
	primary_orange = '#FFC452',
	primary_orange_darker = '#E19501',
	primary_orange_lighter = '#FFDD9B',
	primary_purple = '#D3AAFF',
	primary_purple_darker = '#A250F9',
	primary_purple_lighter = '#EEDFFF',
	primary_yellow = '#F2F661',
	primary_yellow_darker = '#BDC064',
	primary_yellow_lighter = '#FFFEC2',
	red = '#D63E0D',
	white = '#FFFFFF',

	fill_score_connection = '#FFC452',
	fill_score_down = '#D63E0D',
	fill_score_level = '#515151',
	fill_score_resilience = '#3CE7A1',
	fill_score_sexualfitness = '#35C8FE',
	fill_score_stimulation = '#D3AAFF',
	fill_score_up = '#00D17C',
	fill_scale_1 = '#FF0000',
	fill_scale_3 = '#FF6600',
	fill_scale_5 = '#FFCC00',
	fill_scale_7 = '#52FF00',
}

export enum UIPlatform {
	android = 'android',
	ios = 'ios',
	web = 'web',
}

export enum UITheme {
	dark = 'dark',
}

export type UIThemeColors = {
	background_always_dark: BrandColor;
	background_always_light: BrandColor;
	background_expert_note: BrandColor;
	background_ring_secondary: BrandColor;
	background_ring_primary: BrandColor;
	background_level_1: BrandColor;
	background_level_2: BrandColor;
	background_level_3: BrandColor;
	background_bubble_chat: BrandColor;
	background_modal: BrandColor;
	background_neutral: BrandColor;
	background_primary: BrandColor;
	background_secondary: BrandColor;
	border_highlight: BrandColor;
	border_neutral: BrandColor;
	border_primary: BrandColor;
	border_warning: BrandColor;
	content_neutral: BrandColor;
	content_neutral100: BrandColor;
	content_neutral300: BrandColor;
	content_neutral500: BrandColor;
	content_neutral600: BrandColor;
	content_neutral700: BrandColor;
	content_neutral800: BrandColor;
	content_on_dark: BrandColor;
	content_on_fill: BrandColor;
	content_on_light: BrandColor;
	content_primary: BrandColor;
	content_tag: BrandColor;
	content_warning: BrandColor;
	fill_highlight_darker: BrandColor;
	fill_highlight_lighter: BrandColor;
	fill_highlight: BrandColor;
	fill_icon: BrandColor;
	fill_neutral: BrandColor;
	fill_scale_1: BrandColor;
	fill_scale_3: BrandColor;
	fill_scale_5: BrandColor;
	fill_scale_7: BrandColor;
	fill_score_connection: BrandColor;
	fill_score_down: BrandColor;
	fill_score_level: BrandColor;
	fill_score_resilience: BrandColor;
	fill_score_sexualfitness: BrandColor;
	fill_score_stimulation: BrandColor;
	fill_score_up: BrandColor;
	fill_warning: BrandColor;
	poll_background: BrandColor;
	poll_border_hover: BrandColor;
	poll_fill: BrandColor;
};

export type UIThemeColor = keyof UIThemeColors;

const darkUi: UIThemeColors = {
	background_always_dark: BrandColor.neutral_800,
	background_always_light: BrandColor.white,
	background_expert_note: BrandColor.primary_yellow,
	background_ring_secondary: BrandColor.primary_blue,
	background_ring_primary: BrandColor.primary_yellow,
	background_level_1: BrandColor.neutral_700,
	background_level_2: BrandColor.neutral_400,
	background_level_3: BrandColor.neutral_500,
	background_bubble_chat: BrandColor.primary_green_lighter,
	background_modal: BrandColor.black_600,
	background_neutral: BrandColor.neutral_base,
	background_primary: BrandColor.neutral_800,
	background_secondary: BrandColor.neutral_600,
	border_highlight: BrandColor.primary_yellow,
	border_neutral: BrandColor.neutral_200,
	border_primary: BrandColor.white,
	border_warning: BrandColor.red,
	content_neutral: BrandColor.neutral_200,
	content_neutral100: BrandColor.neutral_100,
	content_neutral300: BrandColor.neutral_300,
	content_neutral500: BrandColor.neutral_500,
	content_neutral600: BrandColor.neutral_600,
	content_neutral700: BrandColor.neutral_700,
	content_neutral800: BrandColor.neutral_800,
	content_on_dark: BrandColor.white,
	content_on_fill: BrandColor.black,
	content_on_light: BrandColor.black,
	content_primary: BrandColor.white,
	content_tag: BrandColor.neutral_200,
	content_warning: BrandColor.red,
	fill_highlight: BrandColor.primary_yellow,
	fill_highlight_darker: BrandColor.primary_yellow_darker,
	fill_highlight_lighter: BrandColor.primary_yellow_lighter,
	fill_icon: BrandColor.fill_score_connection,
	fill_neutral: BrandColor.neutral_200,
	fill_scale_1: BrandColor.fill_scale_1,
	fill_scale_3: BrandColor.fill_scale_3,
	fill_scale_5: BrandColor.fill_scale_5,
	fill_scale_7: BrandColor.fill_scale_7,
	fill_score_sexualfitness: BrandColor.fill_score_sexualfitness,
	fill_score_connection: BrandColor.fill_score_connection,
	fill_score_down: BrandColor.fill_score_down,
	fill_score_level: BrandColor.fill_score_level,
	fill_score_resilience: BrandColor.fill_score_resilience,
	fill_score_stimulation: BrandColor.fill_score_stimulation,
	fill_score_up: BrandColor.fill_score_up,
	fill_warning: BrandColor.red,
	poll_background: BrandColor.neutral_600,
	poll_border_hover: BrandColor.primary_yellow,
	poll_fill: BrandColor.primary_yellow,
};

export const themeColors: Record<UITheme, UIThemeColors> = {
	[UITheme.dark]: darkUi,
};

export const maxWidth = 1290;

export const breakpoints = {
	mobile: 0,
	tablet: 768,
	desktop: 1024,
} as const;

export type AppBreakpoints = typeof breakpoints;

export enum BorderRadius {
	extraSmall = 4,
	small = 8,
	regular = 12,
	large = 16,
	extraLarge = 32,
}

export const borderRadius = BorderRadius.regular;

export const embedCalendlyEmbedHeight = 600;

export const NAVBAR_HEIGHT = 76;
export const NAVBAR_HEIGHT_MOBILE = 72;
export const LOGO_WIDTH = 91;

export const radius = {
	xs2: 4,
	xs: 8,
	sm: 12,
	md: 16,
	lg: 20,
	xl: 24,
	xl2: 28,
	xl3: 32,
	xl4: 36,
	pill: 100,
	full: 9999,
} as const;

export const spacing = {
	none: 0,
	xs2: 4,
	xs: 8,
	sm: 12,
	md: 16,
	lg: 20,
	xl: 24,
	xl2: 28,
	xl3: 32,
	xl4: 40,
	xl5: 48,
} as const;

export const iconSize = {
	xs: 16,
	sm: 20,
	md: 24,
	lg: 32,
	xl: 40,
} as const;

// https://doc.wikimedia.org/codex/main/design-tokens/z-index.html
export const zIndex = {
	base: 0,
	aboveContent: 1,
};

const baseTheme = {
	radius,
	spacing,
	iconSize,
	zIndex,
} as const;

export const darkTheme = {
	...baseTheme,
	colors: darkUi,
} satisfies ThemeType;

type ThemeType = typeof baseTheme & { colors: UIThemeColors };

export type UIThemeType = {
	dark: ThemeType;
};

export const SCREEN_MAX_WIDTH = 700;
export const SCREEN_MAX_WIDTH_SM = 416;
