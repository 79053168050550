import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';

type StreamingContextType = {
	streamingFinished: boolean;
	setStreamingFinished: (value: boolean) => void;
	resetStreaming: () => void;
};

const initialState: StreamingContextType = {
	streamingFinished: false,
	setStreamingFinished: () => {},
	resetStreaming: () => {},
};

const StreamingContext = createContext<StreamingContextType>(initialState);

export const StreamingProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [streamingFinished, setStreamingFinishedState] = useState(false);

	const setStreamingFinished = useCallback((value: boolean) => {
		setStreamingFinishedState(value);
	}, []);

	const resetStreaming = useCallback(() => {
		setStreamingFinishedState(false);
	}, []);

	const contextValue = useMemo(
		() => ({
			streamingFinished,
			setStreamingFinished,
			resetStreaming,
		}),
		[streamingFinished, setStreamingFinished, resetStreaming],
	);

	return (
		<StreamingContext.Provider value={contextValue}>
			{children}
		</StreamingContext.Provider>
	);
};

export const useStreaming = () => useContext(StreamingContext);

export default StreamingContext;
